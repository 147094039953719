import * as React from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { Image, Pane, Spinner } from 'evergreen-ui'
import requireAuth from '/~/utils/requireAuth'
import { hubSpotChatController } from './utils/hubSpotChatController'
import { setCampaignReferral } from '/~/utils/referrals'
import { useAuthContext } from '/~/utils/AuthContext'
import { config } from './utils/config'

import { CampaignRouter, PressPage, AboutUs, InvestmentRisk, AccessDenied, Statistic, UnsubscribeEmail } from './pages'
import {
  SignicatBankIdVerificationCallback,
  RedirectExternal,
  Auth,
  Footer,
  NavBar,
  PushMessages,
  LoggedOutDialog,
  VersionChecker,
  ScrollToTop,
} from '/~/components'

const BookInitialMeetingPage = React.lazy(async () => ({
  default: (await import('./pages/BookInitialMeetingPage')).BookInitialMeetingPage,
}))

const RequestToBeContacted = React.lazy(async () => ({
  default: (await import('./pages/RequestToBeContacted')).RequestToBeContacted,
}))

const CampaignsPage = React.lazy(async () => ({ default: (await import('/~/pages/CampaignsPage')).CampaignsPage }))
const CampaignToolPage = React.lazy(() => import('/~/pages/CampaignTool'))
const Career = React.lazy(() => import('/~/pages/Career'))
const CompleteProfilePage = React.lazy(() => import('./pages/CompleteProfilePage'))
const ContactUs = React.lazy(() => import('/~/pages/ContactUs'))
const CookiesPage = React.lazy(() => import('/~/pages/CookiesPage'))
const CountryRoot = React.lazy(async () => ({
  default: (await import('/~/pages/CountryRoots/CountryRoot')).CountryRoot,
}))
const CreateCampaignPage = React.lazy(() => import('/~/pages/CreateCampaignPage'))
const EmbeddedCampaignCardPage = React.lazy(() => import('/~/pages/EmbeddedCampaignCardPage'))
const HomePage = React.lazy(() => import('/~/pages/HomePage'))
const HowItWorks = React.lazy(() => import('/~/pages/HowItWorks'))
const InvestmentOfferPageRouter = React.lazy(async () => ({
  default: (await import('/~/pages')).InvestmentOfferPageRouter,
}))
const InvestorRelationsPage = React.lazy(() => import('/~/pages/InvestorRelationsPage'))
const LandingPageEntrepreneur = React.lazy(async () => ({
  default: (await import('./pages/LandingPageEntrepreneur')).LandingPageEntrepreneur,
}))
const LandingPageInvestor = React.lazy(() => import('/~/pages/LandingPageInvestor'))
const LivePage = React.lazy(async () => ({ default: (await import('/~/pages/LivePage')).LivePage }))
const NewAccountPage = React.lazy(() => import('/~/pages/Account'))
const Page404 = React.lazy(async () => ({ default: (await import('/~/pages/Page404')).Page404 }))
const PrivacyPage = React.lazy(() => import('/~/pages/PrivacyPage'))

const RequestInitialMeetingPage = React.lazy(async () => ({
  default: (await import('./pages/SeekCapitalInitialMeetingPage')).SeekCapitalInitialMeetingPage,
}))

const ResetPassword = React.lazy(async () => ({ default: (await import('/~/pages/ResetPassword')).ResetPassword }))
const RightsPage = React.lazy(() => import('/~/pages/RightsPage'))
const ScandinavianEntrepreneurLandingPage = React.lazy(async () => ({
  default: (await import('./pages/CountryRoots/ScandinavianEntrepreneurLandingPage'))
    .ScandinavianEntrepreneurLandingPage,
}))
const ScandinavianInvestorLandingPage = React.lazy(async () => ({
  default: (await import('/~/pages/CountryRoots/ScandinavianInvestorLandingPage')).ScandinavianInvestorLandingPage,
}))
const ServerIsDownPage = React.lazy(() => import('/~/pages/ServerIsDown'))
const TermsPage = React.lazy(() => import('/~/pages/TermsPage'))
const UtmGenerator = React.lazy(() => import('/~/pages/UtmGenerator'))
const VerifyEmailPage = React.lazy(() => import('/~/pages/VerifyEmailPage'))
const UnfollowInvestmentOffer = React.lazy(async () => ({
  default: (await import('/~/pages/UnfollowInvestmentOffer')).UnfollowInvestmentOffer,
}))

const PageContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Pane flexGrow={2} display='flex' flexDirection='column' position='relative'>
      {children}
    </Pane>
  )
}

const Root = () => {
  const authContext = useAuthContext()

  React.useEffect(() => {
    setCampaignReferral()
    hubSpotChatController(config.hubspotChatDisabled)
  }, [])

  return (
    <React.Suspense
      fallback={
        <Pane
          height='100vh'
          width='100vw'
          top={0}
          right={0}
          position='fixed'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Image marginBottom={25} maxWidth={200} src='/icons/LoadingLogo.svg' alt='folkeinvest logo' />
          <Spinner />
        </Pane>
      }
    >
      <Routes>
        {['/blogg', '/faq', '/podcast', '/folkelekene'].map((path) => (
          <Route path={path} key={path} element={<RedirectExternal />} />
        ))}

        <Route path='/bankid-verifisering/signicat/:callback' Component={SignicatBankIdVerificationCallback} />
        <Route path='/epost-innstillinger' Component={UnsubscribeEmail} />
        <Route path='/unfollow/:unfollowToken' Component={UnfollowInvestmentOffer} />
        <Route path='/epost-verifisering' Component={VerifyEmailPage} />
        <Route path='/fullfør-profil' Component={CompleteProfilePage} />
        <Route path='/innlogging/*' Component={Auth} />
        <Route path='/kampanjekort/:campaignSlug' Component={EmbeddedCampaignCardPage} />
        <Route path='/registrering' element={<Navigate to='/innlogging/registrering' replace />} />
        <Route path='/resetpassword/:token' Component={ResetPassword} />
        <Route path='/sanntid/:slug' Component={LivePage} />

        <Route
          element={
            <Pane display='flex' flexDirection='column' height='100%' minHeight='100vh'>
              <NavBar />
              <PushMessages />
              {!config.ignoreVersionChecker && <VersionChecker />}
              <PageContainer>
                <Pane flexGrow={2} marginY={0} marginX='auto' width='100%'>
                  <ScrollToTop />
                  <Outlet />
                </Pane>
                <Pane>
                  <Footer />
                </Pane>
              </PageContainer>
              <LoggedOutDialog />
              <ServerIsDownPage show={!!authContext.error} />
            </Pane>
          }
        >
          {['no', 'se', 'dk'].map((path) => (
            <Route key={path} path={`/${path}/*`} Component={CountryRoot} />
          ))}

          <Route path='/' Component={HomePage} />
          <Route path='/404' Component={Page404} />
          <Route path='/aksjonaer' Component={InvestorRelationsPage} />
          <Route path='/book-intromote' Component={BookInitialMeetingPage} />
          <Route path='/bli-en-investor' Component={LandingPageInvestor} />
          <Route path='/bli-investor' Component={ScandinavianInvestorLandingPage} />
          <Route path='/bli-kontaktet' Component={RequestToBeContacted} />
          <Route path='/cookies' Component={CookiesPage} />
          <Route path='/ingen-tilgang' Component={AccessDenied} />
          <Route path='/investeringstilbud/*' Component={InvestmentOfferPageRouter} />
          <Route path='/kampanje/*' Component={CampaignRouter} />
          <Route path='/kampanjer/*' Component={CampaignsPage} />
          <Route path='/karriere' Component={Career} />
          <Route path='/kontakt-oss' Component={ContactUs} />
          <Route path='/konto/*' Component={requireAuth(NewAccountPage, '/')} />
          <Route path='/om-oss/*' Component={AboutUs} />
          <Route path='/opprett-emisjon' Component={requireAuth(CreateCampaignPage)} />
          <Route path='/opprett-kampanje' element={<Navigate to={'/opprett-emisjon'} />} />
          <Route path='/personvern' Component={PrivacyPage} />
          <Route path='/presse/*' Component={PressPage} />
          <Route path='/risiko' Component={InvestmentRisk} />
          <Route path='/slik-fungerer-det/*' Component={HowItWorks} />
          <Route path='/sok-kapital-kontakt' Component={RequestInitialMeetingPage} />
          <Route path='/sok-kapital' Component={LandingPageEntrepreneur} />
          <Route path='/soke-kapital' element={<ScandinavianEntrepreneurLandingPage countryCode='NO' />} />

          <Route path='/statistikk' Component={Statistic} />
          <Route path='/terms' Component={TermsPage} />
          <Route path='/utm-generator' Component={UtmGenerator} />
          <Route path='/veileder/*' Component={requireAuth(CampaignToolPage)} />
          <Route path='/vilkar-rettigheter' Component={RightsPage} />

          <Route path='/*' Component={Page404} />
        </Route>
      </Routes>
    </React.Suspense>
  )
}

export default Root

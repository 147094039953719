import * as React from 'react'
import { CircleIcon, Dialog, Pane, Paragraph, SelectionIcon, Text } from 'evergreen-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCustomTheme } from '/fiweb/lib/theme'
import { InvestmentOfferRoute, NavigationMenuItem, NavigationSubmenuItem, NavigationSubmenuItemHash } from './types'
import { getMenuItems } from './menuItems'
import { usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'
import ReactSelect from 'react-select'

interface MobileTabsProps {
  navigate: (path: InvestmentOfferRoute, hash?: NavigationSubmenuItemHash) => void
  selectedItem?: NavigationMenuItem
  selectedSubmenuItem?: NavigationSubmenuItem
}

export const MobileTabs = ({ navigate, selectedItem, selectedSubmenuItem }: MobileTabsProps) => {
  const { spacing, colors } = useCustomTheme()
  const flags = usePublicInvestmentOfferFlags()
  const { isPreview, isReadonly } = flags
  const { formatMessage } = useIntl()
  const menuItems = getMenuItems(flags)
  const [menuOpen, setMenuOpen] = React.useState(false)

  const selectedMenuItemKey = selectedSubmenuItem
    ? `${selectedItem?.key}:${selectedSubmenuItem.hash}`
    : selectedItem?.key

  const list = menuItems.reduce(
    (acc, item) => {
      if (item.submenu) {
        const prefix = formatMessage({ id: item.messageId })
        return [
          ...acc,
          ...item.submenu.map((subItem) => ({
            key: `${item.key}:${subItem.hash}`,
            label: `${prefix} / ${formatMessage({ id: subItem.messageId })}`,
            path: `${item.path}#${subItem.hash}`,
            hideInReadonly: item.hideInReadonly,
          })),
        ]
      }
      return [
        ...acc,
        {
          key: item.key,
          label: formatMessage({ id: item.messageId }),
          path: item.path,
          hideInReadonly: item.hideInReadonly,
        },
      ]
    },
    [] as Array<{ key: string; label: string; path: string; hideInReadonly: boolean }>,
  )

  return (
    <Pane width='100%' maxWidth='500px' paddingX={spacing.xxs}>
      <ReactSelect
        menuIsOpen={false}
        onMenuOpen={() => setMenuOpen(true)}
        isSearchable={false}
        formatOptionLabel={(data) => <Text size={400}>{data.label}</Text>}
        value={list.find((item) => selectedMenuItemKey === item.key) ?? null}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 60,
            width: '100%',
            maxWidth: 600,
          }),
        }}
      />
      <Dialog
        hasHeader={false}
        hasFooter={false}
        hasClose={false}
        hasCancel={false}
        isShown={menuOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEscapePress
        preventBodyScrolling
        onCloseComplete={() => setMenuOpen(false)}
        containerProps={{ background: colors.fiDark10 }}
        contentContainerProps={{ padding: 0, borderRadius: 'inherit' }}
      >
        <Pane display='flex' flexDirection='column' background={colors.fiDark10}>
          <Paragraph color={colors.fiDark} size={500} fontWeight='bold' padding={spacing.xs}>
            <FormattedMessage id='select' />
          </Paragraph>
          {list.map((item) => {
            const isDisabled = item.hideInReadonly && (isReadonly || isPreview)
            return (
              <Pane
                display='flex'
                alignItems='center'
                paddingY={spacing.xxs}
                key={item.key}
                onClick={() => {
                  if (isDisabled) {
                    return
                  }
                  const split = item.path.split('#')
                  setMenuOpen(false)
                  navigate(split[0] as InvestmentOfferRoute, split[1] as NavigationSubmenuItemHash)
                }}
                gap={spacing.xxs}
                background={selectedMenuItemKey === item.key ? colors.fiBlue10 : 'none'}
                paddingX={spacing.xs}
                minHeight={50}
                borderTop={`1px solid ${colors.fiDark20}`}
              >
                <Pane width={25} height={25} color={isDisabled ? colors.fiDark70 : colors.fiDark}>
                  {selectedMenuItemKey !== item.key ? <CircleIcon size={20} /> : <SelectionIcon size={20} />}
                </Pane>
                <Text size={400} color={isDisabled ? colors.fiDark70 : colors.fiDark}>
                  {item.label}
                </Text>
              </Pane>
            )
          })}
        </Pane>
      </Dialog>
    </Pane>
  )
}

import { Pane, Button, ArrowTopRightIcon } from 'evergreen-ui'
import React, { useRef, useState, useEffect } from 'react'
import { useBreakpoint } from '/fiweb/lib'
import styled, { keyframes, css } from 'styled-components'
import { useCustomTheme } from '/fiweb/lib/theme'

interface FlexTextProps {
  content: string
  size?: number
  weight?: number
}
export const FlexText = ({ content, weight }: FlexTextProps) => {
  const words = content.split(' ')

  return (
    <>
      {words.map((word, i) => {
        return (
          <div
            key={`${word}_${i}`}
            style={{ fontWeight: weight ? weight : undefined }}
            dangerouslySetInnerHTML={{ __html: word }}
          />
        )
      })}
    </>
  )
}
const HeroBannerContainer = styled(Pane)<{
  $medium: boolean
  $fadeFromTop: any
  $fadeFromLeft: any
  $animationDelay: number
}>`
${({ $medium, $fadeFromLeft, $fadeFromTop, $animationDelay }) => css`
    .enter{
      opacity:0;
      animation-name: ${$medium ? $fadeFromTop : $fadeFromLeft};
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 2s;
    }
    .second{
      animation-delay: ${($animationDelay * 0.2).toString()}s;
    }
    .third{
      animation-delay: ${($animationDelay * 0.4).toString()}s;
    }
    .fourth{
      animation-delay: ${($animationDelay * 0.6).toString()}s;
    }
    .fifth{
      animation-delay: ${($animationDelay * 0.8).toString()}s;
    }
  `}`

const TextPane = styled(Pane)<{ $medium: boolean }>`
  ${({ theme, $medium }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${$medium ? 'flex-start' : 'flex-start'};

    >div{
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 0.15em;
      padding-right: 0.15em;
      user-select: none;
      flex-shrink: 1;

      &:first-child{
        padding-left: 0.15em;
      }
      &:last-child{
        padding-right: 0.15em;
      }
    }

    &.white{
      color: ${theme.colors.white};
    }
    
    &.black{
      color: ${theme.colors.fiText};
    }
`}`

const fadeFromLeft = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  40% {
    transform: translateX(50px);
    opacity: 0.8;
  }
  75%{
    transform: translateX(-20px);
    opacity: 1;
  }
  100%{
    transform: unset;
    opacity: 1;
  }
`

const fadeFromTop = keyframes`
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  40% {
    transform: translateY(50px);
    opacity: 0.8;
  }
  75%{
    transform: translateY(-20px);
    opacity: 1;
  }
  100%{
    transform: unset;
    opacity: 1;
  }
`

interface Props {
  animated?: boolean
  dark?: boolean
  tag?: string
  title?: string
  content1?: string
  content2?: string
  bgImage?: string
  ctaHref?: string
}

export const HeroBanner = ({ animated, dark, tag, title, content1, content2, bgImage, ctaHref }: Props) => {
  const { medium, large } = useBreakpoint()
  const { spacing } = useCustomTheme()
  const animationDelay = 0.2

  const bannerRef = useRef(null)
  const [isInView, setIsInView] = useState(false)

  const checkInView = () => {
    const rect = bannerRef.current.getBoundingClientRect()
    setIsInView(rect.top < window.innerHeight && rect.bottom >= 0)
  }

  useEffect(() => {
    checkInView()
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', checkInView)
    return () => {
      document.removeEventListener('scroll', checkInView)
    }
  }, [])

  const heroBannerBG = {
    background: bgImage ? `url(${bgImage})` : 'linear-gradient(to right, #FFF 1px, #E4EDE1 2px, #9FCBE6)',
    backgroundSize: 'cover',
    backgroundPosition: medium ? 'left top' : 'left top',
  }

  const animateOnEntry = isInView && animated ? 'enter' : ''
  const colorMode = dark ? 'white' : 'black'

  return (
    <Pane
      ref={bannerRef}
      position='relative'
      display='flex'
      justifyContent='center'
      flexGrow={1}
      minWidth={medium ? '100vw' : undefined}
      minHeight={medium ? 600 : 800}
      overflow='hidden'
      style={heroBannerBG}
      backgroundColor='white'
    >
      <Pane display='flex' position='relative' width='100%'>
        <Pane
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent={medium ? 'flex-start' : 'flex-start'}
          width='100%'
          height='100%'
          maxWidth='800px'
        >
          <HeroBannerContainer
            $medium={medium}
            $fadeFromLeft={fadeFromLeft}
            $fadeFromTop={fadeFromTop}
            $animationDelay={animationDelay}
            width='100%'
            height='100%'
            maxHeight={medium ? '400px' : '450px'}
            maxWidth={medium ? '350px' : '450px'}
            display='flex'
            justifyContent={medium ? 'flex-end' : 'center'}
            alignItems={medium ? 'flex-start' : 'flex-start'}
            flexDirection='column'
          >
            <TextPane
              $medium={medium}
              backgroundColor='rgba(255,255,255,0.2)'
              padding='4px'
              marginLeft={medium ? 22 : 64}
              textTransform='uppercase'
              fontSize={large ? 12 : 14}
              fontWeight={500}
              borderRadius='3px'
              className={`${animateOnEntry} ${colorMode}`}
            >
              <FlexText content={tag} />
            </TextPane>
            <TextPane
              $medium={medium}
              paddingTop={spacing.xxs}
              paddingLeft={medium ? spacing.xs : spacing.l}
              fontSize={large ? 36 : 50}
              fontWeight={700}
              className={`${animateOnEntry} ${colorMode}`}
            >
              {title && <FlexText content={title} />}
            </TextPane>
            <TextPane
              $medium={medium}
              paddingTop='20px'
              paddingLeft={medium ? spacing.xs : spacing.l}
              fontSize={large ? 26 : 32}
              className={`${animateOnEntry} ${colorMode}`}
            >
              {content1 && <FlexText content={content1} weight={600} />}
              {content2 && <FlexText content={content2} weight={300} />}
            </TextPane>
            {ctaHref && (
              <Pane
                display='flex'
                flex='1'
                alignItems='flex-end'
                justifyContent={medium ? 'center' : 'flex-start'}
                className={`${animateOnEntry} ${colorMode}`}
                width={medium ? '100vw' : '50vw'}
                paddingLeft={medium ? 'unset' : spacing.l}
              >
                <Button
                  aria-label={`Les mer om ${content1}`}
                  size={medium ? 'medium' : large ? 'small' : 'medium'}
                  appearance={dark ? 'invertedPrimary' : 'primary'}
                  is='a'
                  href={ctaHref}
                  target='_blank'
                  iconAfter={ArrowTopRightIcon}
                  boxShadow='10px'
                >
                  Les mer
                </Button>
              </Pane>
            )}
          </HeroBannerContainer>
        </Pane>
      </Pane>
    </Pane>
  )
}

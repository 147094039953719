const env = (window as any)._ENV as Record<string, string | undefined>

export const config = {
  authHost: `${env.VITE_APP_AUTH_API}/auth`,
  pdfHost: env.VITE_APP_PDF_API,
  issuerHost: env.VITE_APP_ISSUER_HOST,
  issuerApiGraphQLUrl: env.VITE_APP_ISSUER_API_GRAPHQL_URL || undefined,
  issuerApiGraphQLSubscriptionUrl: env.VITE_APP_ISSUER_API_GRAPHQL_SUBSCRIPTION_URL || undefined,
  graphqlUrl: env.VITE_APP_API_GRAPHQL,
  graphqlSubscriptionUrl: env.VITE_APP_API_SUBSCRIPTION,
  graphqlErrorLogginDisabled: env.VITE_APP_CLIENT_GRAPHQL_ERROR_LOGGING_DISABLED === 'true',

  hubspotChatDisabled: env.VITE_APP_DISABLE_HUBSPOT_CHAT === 'true',

  publicFilesUrl: env.VITE_APP_PUBLIC_FILES_URL,

  googleClientId: env.VITE_APP_GOOGLE_CLIENT_ID,
  disableErrorReporting: env.VITE_APP_DISABLE_ERROR_REPORTING === 'true',

  loggedInKey: 'fi-public-authenticated',
  userInvokedLogoutKey: 'user-invoked-logout',

  signicatInternationalEnabled: env.VITE_APP_SIGNICAT_INTERNATIONAL_ENABLED === 'true',

  newIssuer: {
    bookingEnabled: env.VITE_APP_NEW_ISSUER_BOOKING_ENABLED === 'true',
  },

  ignoreVersionChecker: env.VITE_APP_IGNORE_CLIENT_VERSION_CHECKER === 'true',
  showFormidlingSectionOnHomepage: env.VITE_APP_SHOW_FORMIDLING_SECTION_ON_HOMEPAGE === 'true',
}

import * as React from 'react'
import { Button, Pane, PersonIcon, Spinner, toaster } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { breakpoints } from '/fiweb/lib'
import styled, { css } from 'styled-components'
import { useCustomTheme } from '/fiweb/lib/theme'

interface Props {
  onClose?: () => void
  specialFrontpage?: boolean
  isScrolled?: boolean
}

const StyledPane = styled(Pane)`
  ${({ theme }) => css`
    display: flex;
    min-width: 250px;
    gap: ${theme.spacing.xs};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    @media ${breakpoints.large} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
`}`

interface AuthButtonProps {
  $specialFrontpage?: boolean
  $isScrolled?: boolean
}

const StyledAuthButton = styled(Button).attrs({ appearance: 'text', size: 'small' })<AuthButtonProps>`
  ${({ theme, $specialFrontpage, $isScrolled }) => css`
    min-width: 150px;
    border: 1px solid;
    background-color: ${$specialFrontpage && !$isScrolled ? theme.colors.fiTint : undefined};
    border-color: ${$specialFrontpage && !$isScrolled ? undefined : $isScrolled ? theme.colors.fiDark : undefined};
    &:not([disabled]):hover {
      background-color: ${$specialFrontpage && !$isScrolled ? theme.colors.white : undefined};
    }
    @media ${breakpoints.large} {
      width: 100%;
      max-width: 250px;
    }
`}`

export const NavBarAuth = ({ onClose, specialFrontpage, isScrolled }: Props) => {
  const { user, loading, logOut } = useAuthContext()
  const { colors } = useCustomTheme()
  const location = useLocation()

  const handleLogout = async () => {
    onClose?.()
    await logOut()
    toaster.success('Du er nå logget ut')
  }

  return (
    <StyledPane>
      <Button
        className='navElement'
        size='small'
        style={{ color: specialFrontpage && !isScrolled ? colors.fiTint : undefined }}
        appearance='text'
        onClick={user ? handleLogout : () => onClose?.()}
        is={user ? undefined : RouterLink}
        to={
          user
            ? undefined
            : {
                pathname: '/innlogging',
                search: location.pathname === '/' ? undefined : `redirectToAfterAuth=${location.pathname}`,
              }
        }
      >
        {user ? 'Logg ut' : 'Logg inn'}
      </Button>
      <StyledAuthButton
        $specialFrontpage={specialFrontpage}
        $isScrolled={isScrolled}
        className='navElement'
        iconBefore={user ? PersonIcon : null}
        is={RouterLink}
        onClick={() => onClose?.()}
        to={user ? '/konto' : '/innlogging/registrering'}
      >
        {loading ? <Spinner size={16} /> : user ? 'Min Profil' : 'Lag Profil'}
      </StyledAuthButton>
    </StyledPane>
  )
}

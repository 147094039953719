import * as React from 'react'
import { Pane, Paragraph, Spinner } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { FormattedMessage } from 'react-intl'
import { Message } from '../lib/i18n/types'

type Props = {
  children?: React.ReactNode
  size?: number
} & (
  | {
      text?: Message
      textAsString?: never
    }
  | {
      text?: never
      textAsString?: string
    }
)

export const CenteredLoading = ({ text, textAsString }: Props) => {
  const { spacing } = useCustomTheme()
  return (
    <Pane height='100%' width='100%' display='flex' alignItems='center' gap={spacing.xs} justifyContent='center'>
      <Spinner />
      <Paragraph>
        {textAsString ? (
          textAsString
        ) : text ? (
          <FormattedMessage id={text} />
        ) : (
          <>
            <FormattedMessage id='loading' />
            ...
          </>
        )}
      </Paragraph>
    </Pane>
  )
}

export const SuspenseLoading: React.FC<Props> = ({ children, text }) => (
  <React.Suspense fallback={<CenteredLoading text={text} />}>{children}</React.Suspense>
)

interface LoadingProps {
  text?: string
  size?: number
}

export const Loading = ({ text, size }: LoadingProps) => {
  const theme = useCustomTheme()

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' marginY={theme.spacing.xl}>
      <Spinner size={size} />
      {text && text.length > 0 && <Paragraph>{text}</Paragraph>}
    </Pane>
  )
}

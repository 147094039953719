import * as React from 'react'
import { navItems } from './navItems'
import { Button, ChevronDownIcon, Pane, Popover } from 'evergreen-ui'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useAuthContext } from '/~/utils/AuthContext'
import { FormattedMessage } from 'react-intl'

type Props = Omit<(typeof navItems)[number], 'name'>

export const NavBarItem = ({ labelId, subMenus, to }: Props) => {
  const { spacing } = useCustomTheme()
  const { user } = useAuthContext()
  const location = useLocation()
  const isActive = subMenus
    ? !!subMenus.find((sm) => location.pathname.includes(sm.to.split('?')[0]))
    : location.pathname.includes(to)

  if (!subMenus) {
    return (
      <Button className='navElement' is={RouterLink} to={to} size='small' appearance='text' isActive={isActive}>
        <FormattedMessage id={labelId} />
      </Button>
    )
  }

  return (
    <Popover
      position='bottom-left'
      content={({ close }) => (
        <Pane display='flex' flexDirection='column' alignItems='flex-start' padding={spacing.xs}>
          {subMenus
            ?.filter((o) => !(o.hideWhenLoggedIn && user))
            .map((sm) => (
              <Button
                className='navElement'
                is={RouterLink}
                onClick={close}
                to={sm.to}
                size='small'
                key={sm.key}
                appearance='text'
                fontWeight='inherit'
              >
                <FormattedMessage id={sm.labelId} />
              </Button>
            ))}
        </Pane>
      )}
    >
      <Button className='navElement' size='small' isActive={isActive} appearance='text' iconAfter={ChevronDownIcon}>
        <FormattedMessage id={labelId} />
      </Button>
    </Popover>
  )
}

import dayjs from 'dayjs'
import { Button, ButtonProps, Pane } from 'evergreen-ui'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { useCustomTheme } from '/fiweb/lib/theme'

const StyledPane = styled(Pane)<{ $fillDegree: number }>`
  ${({ theme, $fillDegree }) => css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
   
    ${
      $fillDegree < 180
        ? css`
            background: linear-gradient(270deg, transparent 50%, white 50%), linear-gradient(${$fillDegree - 90}deg, transparent 50%, ${theme.colors.red500} 50%);
            border: 1px solid ${theme.colors.fiDark20};
          `
        : $fillDegree < 360
          ? css`
            background: linear-gradient(90deg, transparent 50%, ${theme.colors.red500} 50%), linear-gradient(${$fillDegree - 90}deg, transparent 50%, ${theme.colors.red500} 50%);
            border: 1px solid ${theme.colors.fiDark20};
          `
          : css`background: radial-gradient(${theme.colors.fiGreen70} 25%, white 50%);`
    }
    

`}`

interface Props extends ButtonProps {
  delayInMs?: number
  children?: ({ canConfirm }: { canConfirm: boolean }) => React.ReactNode | React.ReactNode
}

export const ConfirmationButton = ({ delayInMs, ...buttonProps }: Props) => {
  const [timestamp] = React.useState(delayInMs ? dayjs().add(delayInMs, 'ms').valueOf() : null)
  const [timeLeftInMs, setTimeLeftInMs] = React.useState(timestamp ? timestamp - dayjs().valueOf() : 0)
  const { spacing } = useCustomTheme()

  React.useEffect(() => {
    if (timestamp) {
      const timer = setInterval(() => {
        if (timestamp - dayjs().valueOf() < 0) {
          clearInterval(timer)
        }
        setTimeLeftInMs(timestamp - dayjs().valueOf())
      }, 20)

      return () => clearInterval(timer)
    }
  }, [timestamp])

  const canConfirm = timeLeftInMs <= 0

  const fillDegree = delayInMs
    ? Math.max(Math.min(360, Math.floor(((delayInMs - timeLeftInMs) / delayInMs) * 360)), 0)
    : 100

  return (
    <Pane display='flex' gap={spacing.xxs} alignItems='center'>
      <Button {...buttonProps} disabled={!canConfirm || !!buttonProps.disabled}>
        {typeof buttonProps.children === 'function' ? buttonProps.children({ canConfirm }) : buttonProps.children}
      </Button>
      {delayInMs && <StyledPane $fillDegree={fillDegree} />}
    </Pane>
  )
}
